<template>
  <v-dialog
    v-model="scorecardVisible"
    width="160vmin"
    eager
  >
    <template #activator="{ on }">
      <v-btn
        v-if="tiledButton"
        class="d-flex align-center"
        tile
        color="onyx white--text"
        elevation="0"
        :loading="downloading"
        v-on="$vuetify.breakpoint.mdAndUp ? on : undefined"
        @click="downloadScorecardIfSmall"
      >
        <v-icon
          left
          small
        >
          mdi-poll
        </v-icon>
        {{ $vuetify.breakpoint.mdAndUp ? 'View' : 'Download' }} scorecard
      </v-btn>
      <v-btn
        v-else
        class="mb-5"
        outlined
        rounded
        text
        color="onyx"
        elevation="0"
        :loading="downloading"
        v-on="$vuetify.breakpoint.mdAndUp ? on : undefined"
        @click="downloadScorecardIfSmall"
      >
        {{ $vuetify.breakpoint.mdAndUp ? 'View' : 'Download' }} scorecard
      </v-btn>
    </template>
    <v-card
      v-if="scorecardVisible"
      style="height: 90vmin;"
      class="d-flex flex-column pt-5"
    >
      <v-card-text style="flex-grow: 1;">
        <v-window
          :value="scorecardPage - 1"
          horizontal
          style="height: 100%;"
        >
          <v-window-item
            v-for="page in 3"
            :key="page"
            style="height: 100%;"
          >
            <div
              v-if="loading"
              class="text-center"
            >
              <v-progress-linear
                color="shamrock accent-4"
                indeterminate
                rounded
                height="6"
              />
              <div class="title">
                ...Loading Scorecard
              </div>
            </div>
            <iframe
              v-show="!loading"
              id="iframeid"
              ref="iframe"
              scrolling="no"
              :src="scorecardLinks[page]"
              class="iframe"
              @load="load"
            />
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-pagination
          v-model="scorecardPage"
          :length="2"
          color="shamrock"
          elevation="0"
          class="mb-2"
          style="width: fit-content;"
        />
        <!-- <v-btn
          elevation="0"
          tile
          color="onyx"
          dark
          class="ma-3 mt-0"
          :href="`${scorecardLinks[page]}&print=true`"
          target="_blank"
        >
          <v-icon
            left
            style="font-size: 20px;"
          >
            mdi-printer
          </v-icon>
          Print
        </v-btn> -->
        <v-btn
          elevation="0"
          tile
          color="onyx"
          dark
          class="ma-3 mt-0"
          :loading="downloading"
          @click="downloadScorecard"
        >
          <v-icon
            left
            style="font-size: 20px;"
          >
            mdi-download
          </v-icon>
          Download as pdf
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { captureException } from '@sentry/vue'

export default {
  name: 'ScorecardDialog',
  props: {
    versionId: {
      type: String,
      default: null
    },
    tiledButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pagesLoaded: [],
      scorecardVisible: false,
      downloading: false,
      loading: true,
      scorecardPage: 1
    }
  },
  computed: {
    scorecardLinks () {
      return new Array(3).fill(null).map((x, i) => `${this.$baseURL}/scorecard?version=${this.versionId}&rnd=${Date.now()}&page=${i}`)
    },
    scorecardDownloadLink () {
      return `${this.$baseURL}/api/versions/${this.versionId}/download-scorecard`
    }
  },
  watch: {
    scorecardPage (val) {
      if (!this.pagesLoaded[val]) {
        this.loading = true
      }
    },
    scorecardVisible (val) {
      if (val) {
        this.$nextTick(() => {
          // refresh scorecard when visible
          this.$refs.iframe.forEach(frame => {
            frame.src += ''
          })
        })
      } else {
        this.scorecardPage = 1
        this.loading = true
        this.pagesLoaded = []
      }
    }
  },
  methods: {
    downloadScorecardIfSmall () {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.downloadScorecard()
      }
    },
    async downloadScorecard () {
      try {
        this.downloading = true
        const { data: html } = await this.$axios.get(`/versions/${this.versionId}/download-scorecard`)

        // const { data } = await this.$axios.post('https://us-central1-lcc1-236412.cloudfunctions.net/htmlToPdf', { html }, {
        const { data } = await this.$axios.post('/utility/html-to-pdf', { html, versionId: this.versionId }, {
          responseType: 'blob'
          // headers: {
          //   // Authorization: process.env.CLOUD_FUNCTION_KEY,
          //   Accept: 'application/pdf'
          // }
        })
        const blob = new Blob([data], { type: 'application/pdf' })
        var userAgent = window.navigator.userAgent
        if (window.navigator.msSaveOrOpenBlob) { // IE 11+
          window.navigator.msSaveOrOpenBlob(blob, 'Scorecard.pdf')
        } else if (userAgent.match('FxiOS')) { // FF iOS
          self.showSnackbar("Scorecard downloading isn't supported on this browser. Please try Safari.")
        } else if (userAgent.match('CriOS')) { // Chrome iOS
          var reader = new FileReader()
          reader.onloadend = function () { window.open(reader.result) }
          reader.readAsDataURL(blob)
        } else if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) { // Safari & Opera iOS
          var url = window.URL.createObjectURL(blob)
          window.location.href = url
        } else {
          const link = window.URL.createObjectURL(blob)
          var el = window.document.createElement('a')
          window.document.body.appendChild(el)
          el.href = link
          el.download = 'Carbon_Positive_Design_Scorecard.pdf'
          el.click()
          setTimeout(function () {
            window.document.body.removeChild(el)
            window.URL.revokeObjectURL(link)
          }, 100)
        }
        this.downloading = false
      } catch (err) {
        this.downloading = false
        this.$store.dispatch('showSnackbar', { color: 'error', text: 'Error downloading scorecard. Please try again, or get in touch if error persists.' })
        throw err
      }
    },
    load () {
      this.loading = false
      this.pagesLoaded[this.scorecardPage] = true
    },
    onDownload () {
      this.downloading = true
      setTimeout(() => {
        this.downloading = false
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: 100%;
  border: none;

}
</style>
